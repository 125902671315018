import {scrollToSelector} from "@spoferan/spoferan-ts-core";

export function useInfoPage() {

    const scrollToContent = () => {
        scrollToSelector('#content')
    }

    return {
        scrollToContent,
    }
}